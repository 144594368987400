import { FETCH_REPORTS } from '../actions/reports';

const INITIAL_STATE = {
  reportsList: []
};

const reportsReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH_REPORTS}_FULFILLED`:
      return {
        ...state,
        reportsList: [...action.payload.reportsdata]
      };
    default:
      return state;
  }
};

export default reportsReducers;
