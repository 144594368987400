import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import apiReducer from './apiReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import surveysReducer from './surveysReducer';
import siteReducer from './siteReducer';
import deviceReducer from './deviceReducer';
import sensorConfigsReducer from './sensorConfigsReducer';
import modelReducer from './modelReducer';
import alarmReducer from './alarmReducer';
import alertReducer from './alertReducer';
import extractionReducer from './extractionReducer';
import reportsReducer from './reportsReducer';
import calculusReducer from './calculusReducer';
import timePeriodReducer from './timePeriodReducer';

// import enhancementReducer from './enhancementReducer';

export default function createRootReducer() {
  const combinedReducers = combineReducers({
    api: apiReducer,
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    surveys: surveysReducer,

    // Add tree key before going through combinedReducers. It avoid a warning while calling redux/combinedReducers
    tree: (state = {}) => state,

    site: siteReducer,
    device: deviceReducer,
    sensorConfigs: sensorConfigsReducer,
    model: modelReducer,
    alarm: alarmReducer,
    alert: alertReducer,
    extraction: extractionReducer,
    calculus: calculusReducer,
    reports: reportsReducer,
    i18n: i18nReducer,
    timePeriods: timePeriodReducer
  });

  // Return a root reducer, build with the classic reducers and the treeReducer that uses previous reducer states
  return (state, action) => {
    const intermediateState = combinedReducers(state, action);
    return intermediateState;
    // TODO: remove enhancement reducer

    // return enhancementReducer(intermediateState, action);
  };
}
