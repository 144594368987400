import axios from './axios';

const sensorServices = {
  create: (sensor) => {
    const options = {
      method: 'POST',
      url: 'sensors',
      data: sensor
    };
    return axios(options);
  },

  createMany: (sensors) => {
    const options = {
      method: 'POST',
      url: 'sensors/createmany',
      data: sensors
    };
    return axios(options);
  },

  fetch: () => {
    const options = {
      method: 'GET',
      url: 'sensors'
    };
    return axios(options);
  },

  fetchMany: (sensors) => {
    const options = {
      method: 'POST',
      url: 'sensors/getmany',
      data: sensors
    };
    return axios(options);
  },

  fetchById: (sensorId) => {
    const options = {
      method: 'GET',
      url: `sensors/${sensorId}`
    };
    return axios(options);
  },

  update: (sensorId, sensor) => {
    const options = {
      method: 'PATCH',
      url: `sensors/${sensorId}`,
      data: sensor
    };
    return axios(options);
  },

  delete: (sensorId) => {
    const options = {
      method: 'DELETE',
      url: `sensors/${sensorId}`
    };
    return axios(options);
  }
};

export default sensorServices;
