import { REDUX_FORM_TYPES } from '@tools/constants';
import { withFormReducer, withFormInitialState } from './withFormReducer';
import { CLONE_MODEL, CREATE_MODEL, UPDATE_MODEL, FETCH_MODELS, DELETE_MODEL } from '../actions/model';

const { MODELS } = REDUX_FORM_TYPES;

const initialState = {
  ...withFormInitialState,
  list: []
};

const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CLONE_MODEL}_FULFILLED`:
    case `${CREATE_MODEL}_FULFILLED`:
      return {
        ...state,
        list: [...state.list, action.payload.model]
      };

    case `${UPDATE_MODEL}_FULFILLED`:
      return {
        ...state,
        list: state.list.map((model) => (model._id === action.payload.model._id ? action.payload.model : model))
      };

    case `${FETCH_MODELS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.models
      };

    case `${DELETE_MODEL}_FULFILLED`:
      return {
        ...state,
        list: state.list.filter((model) => model._id !== action.payload.model._id)
      };

    default:
      return state;
  }
};

export default withFormReducer(MODELS, modelReducer);
