import { jwtDecode } from 'jwt-decode';
import { getLocalStorage, prefixedKey } from '@tools/localStorage';
import { TOKEN_STORAGE_KEY } from '@tools/constants';
import withSyncStorage from './withSyncStorage';
import { LOGOUT, FORCE_LOGOUT, LOGIN, INIT_COMPLETE } from '../actions/auth';

const token = getLocalStorage(prefixedKey(TOKEN_STORAGE_KEY));
const initialDecodeUser = (token && jwtDecode(token)) || {};

const initialState = {
  ...initialDecodeUser,
  token,
  isForcedLogout: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN}_FULFILLED`: {
      const decodeUser = jwtDecode(action.payload.token);
      return {
        ...state,
        ...decodeUser,
        token: action.payload.token,
        isForcedLogout: false
      };
    }

    case INIT_COMPLETE:
      return {
        ...state,
        fullInit: true
      };

    case LOGOUT:
      return {
        ...state,
        decodeUser: null,
        token: null
      };

    case FORCE_LOGOUT:
      return {
        ...state,
        // True if 401 when user logged in, false otherwise (to avoid displaying message when receiving 401 in logging)
        isForcedLogout: !!state.token,
        decodeUser: null,
        token: null
      };

    default:
      return state;
  }
};

export default withSyncStorage(authReducer, 'token', TOKEN_STORAGE_KEY);
