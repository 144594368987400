import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getThemeVariables, getRubixTheme } from '@tools/themeTools';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { selectTheme } from '@redux/selectors/preferences';

export default function ThemeWrapper({ children }) {
  const theme = useSelector(selectTheme);

  const applyThemeVariables = (themeVars) => {
    const root = document.documentElement;
    const keys = Object.keys(themeVars);

    keys.forEach((key) => {
      root.style.setProperty(key, themeVars[key]);
    });
  };

  useEffect(() => {
    const themeVariables = getThemeVariables(theme, true); // Replace this with your theme logic
    applyThemeVariables(themeVariables);
  }, [theme]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getRubixTheme(theme)}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
