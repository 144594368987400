import calculusServices from '@services/calculus';

export const CREATE_CALCULUS = 'CREATE_CALCULUS';
export const CREATE_CALCULUS_FROM_CSV = 'CREATE_CALCULUS_FROM_CSV';
export const UPDATE_CALCULUS = 'UPDATE_CALCULUS';
export const FETCH_CALCULUS = 'FETCH_CALCULUS';
export const FETCH_CALCULUS_BY_ID = 'FETCH_CALCULUS_BY_ID';
export const DELETE_CALCULUS = 'DELETE_CALCULUS';
export const FETCH_COMPUTED_CALCULUS = 'FETCH_COMPUTED_CALCULUS';
export const VALIDATE_CALCULUS_CSV = 'VALIDATE_CALCULUS_CSV';
export const DOWNLOAD_CALCULUS = 'DOWNLOAD_CALCULUS';
export const CHANGE_MIGRATE_CALCULUS = 'CHANGE_MIGRATE_CALCULUS';

export function createCalculus(calculus) {
  if (calculus.tosave || calculus.update) {
    return {
      type: UPDATE_CALCULUS,
      payload: calculusServices.update(calculus)
    };
  }

  return {
    type: CREATE_CALCULUS,
    payload: calculusServices.create(calculus)
  };
}

export function createCalculusFromCSV(calculus) {
  return {
    type: CREATE_CALCULUS_FROM_CSV,
    payload: calculusServices.create(calculus)
  };
}

export function fetchCalculus(type) {
  return {
    type: FETCH_CALCULUS,
    payload: calculusServices.fetchCalculus(type)
  };
}

export function fetchCalculusById(id) {
  return {
    type: FETCH_CALCULUS_BY_ID,
    payload: calculusServices.fetchCalculusById(id)
  };
}

export function deleteCalculus(id) {
  return {
    type: DELETE_CALCULUS,
    payload: calculusServices.deleteCalculus(id)
  };
}

export function fetchComputedCalculus(id) {
  return {
    type: FETCH_COMPUTED_CALCULUS,
    payload: calculusServices.fetchComputedCalculus(id)
  };
}

export function changeMigrateCalculus(id, bool) {
  return {
    type: CHANGE_MIGRATE_CALCULUS,
    payload: calculusServices.changeMigrate(id, bool)
  };
}
