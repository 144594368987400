import calculus from 'Calculus';
import { I18n } from 'react-redux-i18n';

export const SUPER_ADMIN = 'ROLE_SUPER_ADMIN';

// DEVICES
// This is for the Map, All device kinds are treated same
export const DEVICE_TYPES = {
  DEVICE: 'DEVICE',
  CLUSTER_DEVICES: 'CLUSTER_DEVICES',
  IMPACT: 'IMPACT',
  WS: 'WS',
  CLUSTER_SITES: 'CLUSTER_SITES',
  SITE: 'SITE'
};

// VIEW
export const VIEW_TYPES = {
  MAP: 'MAP',
  BUILDING: 'BUILDING',
  LIST: 'LIST',
  ALERTS: 'ALERTS'
};

// SENSORS
export const DEFAULT_SENSOR_CONFIG = {
  category: 'other',
  limits: {
    min: 0,
    max: 100
  }
};

// CALCULUS
export const CALCULUS_TYPES = calculus.reduce((acc, { type }) => ({ ...acc, [type]: type }), {});
export const SIGMA_VALUES = [2, 3];
export const SQC_MODE_VALUES = [
  ['correlated', I18n.t('page.statistics.create.form.mode.correlated')],
  ['independant', I18n.t('page.statistics.create.form.mode.independent')]
];

export const VIRTUAL_SENSORS = 'VIRTUAL_SENSORS';

// ALARMS
export const DEFAULT_ALARM = {
  sentinel: false,
  delay: 10 * 60, // 10 minutes
  limits: {}
};
export const TIMEPERIODS = 'timeperiods';

export const DEVICE = 'device';
export const SITE = 'site';
export const SENSOR_TYPE = 'sensorType';
export const SENSOR = 'sensor';

export const TREE_NODE_TYPES = {
  SITE,
  DEVICE,
  SENSOR_TYPE,
  SENSOR,
  NODE_TYPES: [SITE, DEVICE, SENSOR_TYPE, SENSOR]
};

export const TOKEN_STORAGE_KEY = 'token';
export const PREF_STORAGE_KEY = 'preferences';
export const DARK = 'dark';
export const LIGHT = 'light';
export const THEMES = [DARK, LIGHT];

// MAP LAYERS
export const RECT_OPTIONS = {
  color: 'rgba(128,128,128,0.5)',
  stroke: true,
  fill: true,
  fillOpacity: 0
};

// PLUME DELAY
export const PLUME_REFRESH_DELAY = 300000;

// REAL TIME DELAY
export const REAL_TIME_FETCH_INTERVAL = 10000;

// DASHBOARD DELAY
export const DASHBOARD_FETCH_INTERVAL = 300000;

// DEFAULT
export const DEFAULT = 'DEFAULT';

// API ACTIONS
export const API_ACTIONS = ['CREATE', 'UPDATE', 'DELETE', 'GET', 'FETCH', 'CLONE'];

// REDUX_FORM_TYPES
export const REDUX_FORM_TYPES = {
  TIME_PERIODS: 'TIME_PERIODS',
  SITE: 'SITE',
  DEVICE: 'DEVICE',
  USERS: 'USERS',
  MODELS: 'MODELS',
  ALARMS: 'ALARMS',
  CALCULUS: 'CALCULUS',
  SURVEYS: 'SURVEYS',
  EXTRACTION: 'EXTRACTION',
  VIRTUALSENSORS: 'VIRTUALSENSORS',
  SENSORS: 'SENSORS'
};

// MODEL_TYPE
export const STATS_TYPE = {
  LDA: 'LDA'
};

export const EXTRACTION_TYPES = ['sensors', 'survey'];

// Extraction parameters need translation for UI
export const SAMPLING_VALUES = [
  ['10 seconds', 'page.extractions.samplingValues.10sec'],
  ['1 minute', 'page.extractions.samplingValues.1min'],
  ['5 minutes', 'page.extractions.samplingValues.5min'],
  ['15 minutes', 'page.extractions.samplingValues.15min'],
  ['30 minutes', 'page.extractions.samplingValues.30min'],
  ['1 hour', 'page.extractions.samplingValues.1h'],
  ['1 day', 'page.extractions.samplingValues.1d']
];
export const SAMPLING_MODE_VALUES = [
  ['MIN', 'page.extractions.samplingModes.min'],
  ['MAX', 'page.extractions.samplingModes.max'],
  ['AVG', 'page.extractions.samplingModes.avg']
];

export const LEVELS = [1, 2, 3];
export const MODES = ['influences', 'recognition'];

export const DEFAULT_HELPER_TEXT = I18n.t('global.form.requiredField');

export const TABLE_EMPTY_CELL = '-';

export const STATISTICS_MODE = 'statistics';
export const JEXL_MODE = 'jexl';
export const COMFORT_MODE = 'comfort';
export const VIRAL_MODE = 'viral';
export const SMOKE_MODE = 'smoke';
export const CONFINEMENT_MODE = 'confinement';
export const AQI_MODE = 'aqi';

export const VIRTUAL_SENSOR_MODES = [
  STATISTICS_MODE,
  JEXL_MODE,
  COMFORT_MODE,
  VIRAL_MODE,
  SMOKE_MODE,
  CONFINEMENT_MODE,
  AQI_MODE
];

export const MULTIPLE_CHOICE = 'multiple_choice';
export const SLIDER = 'slider';
export const QUESTION_TYPES = [MULTIPLE_CHOICE, SLIDER];

export const AQI = 'aqi';
export const AQI_PRECISION = 'aqi_precision';
export const AQI_DASHBOARD = 'aqi_dashboard';
export const VIRAL = 'viral_index';
export const COMFORT = 'comfort';
export const SMOKE = 'is_smoke';
export const CONFINEMENT = 'containment_index';

export const FETCH_INTERVALS = [
  [SAMPLING_VALUES[0][0], 10],
  [SAMPLING_VALUES[0][1], 60],
  [SAMPLING_VALUES[0][4], 500],
  [SAMPLING_VALUES[0][5], 3600],
  [SAMPLING_VALUES[0][6], 86400]
];

export const NORMAL = 'default';
export const INFO = 'info';
export const WARN = 'warn';
export const CRIT = 'crit';
export const NO_LEVEL = 'noLevel';

export const ALARM_LEVELS = {
  NORMAL,
  INFO,
  WARN,
  CRIT,
  NO_LEVEL
};

export const ALARM_LEVELS_DASHBOARD = [NORMAL, INFO, WARN, CRIT];

export const ALARM_DEFAULT_LEVEL = 0;
export const ALARM_INFO_LEVEL = 1;
export const ALARM_WARNING_LEVEL = 2;
export const ALARM_CRITICAL_LEVEL = 3;
export const ALARM_NOLEVEL_LEVEL = 4;

export const DASHBOARD_DEVICE_LINE_VIEW = 'DASHBOARD_DEVICE_LINE_VIEW';
export const DASHBOARD_DEVICE_GRID_VIEW = 'DASHBOARD_DEVICE_SQUARE_VIEW';

export const INDEX_DEFINITIONS = {
  [AQI_PRECISION]: [
    {
      min: 0,
      max: 1,
      color: '#77be69',
      dashboardColor: '#7FD6B4', // GREEN
      label: 'aqi.good',
      alertEquivalent: ALARM_DEFAULT_LEVEL,
      labelColor: '#48C494'
    },
    {
      min: 1,
      max: 2,
      label: 'aqi.moderate',
      color: '#FFDC00',
      dashboardColor: '#F9E089', // YELLOW
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#F4CE48'
    },
    {
      min: 2,
      max: 3,
      label: 'aqi.degraded',
      color: '#ED862F',
      dashboardColor: '#F6B57A', // ORANGE
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#EA9445'
    },
    {
      min: 3,
      max: 4,
      label: 'aqi.veryBad',
      color: '#db267c',
      dashboardColor: '#D7524B', // RED
      alertEquivalent: ALARM_CRITICAL_LEVEL,
      labelColor: '#E62E2E'
    },
    {
      min: 4,
      max: 5,
      label: 'aqi.extremelyBad',
      color: '#950f00',
      dashboardColor: '#C45894', // PURPLE
      alertEquivalent: ALARM_CRITICAL_LEVEL,
      labelColor: '#AE1369'
    }
  ],
  [AQI_DASHBOARD]: [
    {
      min: 0,
      max: 4,
      color: '#77be69',
      dashboardColor: '#48c494',
      label: 'aqi.good',
      alertEquivalent: ALARM_DEFAULT_LEVEL,
      labelColor: '#48c494'
    },
    {
      min: 4,
      max: 5,
      label: 'aqi.moderate',
      color: '#88d2f6',
      dashboardColor: '#f0d50a',
      alertEquivalent: ALARM_INFO_LEVEL,
      labelColor: '#f0d50a'
    },
    {
      min: 5,
      max: 6,
      label: 'aqi.bad',
      color: '#ED862F',
      dashboardColor: '#ff8605',
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#ff8605'
    },
    {
      min: 6,
      max: 7,
      label: 'aqi.veryBad',
      color: '#961d37',
      dashboardColor: '#fd1717',
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#fd1717'
    }
  ],
  [AQI]: [
    {
      min: 0,
      max: 1,
      color: '#77be69',
      label: 'aqi.good',
      dashboardColor: '#7FD6B4',
      alertEquivalent: ALARM_DEFAULT_LEVEL,
      labelColor: '#48C494'
    },
    {
      min: 1,
      max: 2,
      label: 'aqi.moderate',
      color: '#FFDC00',
      dashboardColor: '#F9E089',
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#F4CE48'
    },
    {
      min: 2,
      max: 3,
      label: 'aqi.degraded',
      color: '#ED862F',
      dashboardColor: '#F6B57A',
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#EA9445'
    },
    {
      min: 3,
      max: 4,
      label: 'aqi.veryBad',
      color: '#db267c',
      dashboardColor: '#BC2554',
      alertEquivalent: ALARM_CRITICAL_LEVEL
    },
    {
      min: 4,
      max: 5,
      label: 'aqi.extremelyBad',
      color: '#950f00',
      dashboardColor: '#D7524B',
      alertEquivalent: ALARM_CRITICAL_LEVEL,
      labelColor: '#E62E2E'
    }
  ],
  [COMFORT]: [
    {
      min: 0,
      max: 2.5,
      color: '#77be69',
      label: 'comfort.good',
      dashboardColor: '#48c494',
      alertEquivalent: ALARM_DEFAULT_LEVEL,
      labelColor: '#48C494'
    },
    {
      min: 2.5,
      max: 5,
      color: '#FFDC00',
      label: 'comfort.moderate',
      dashboardColor: '#f0d50a',
      alertEquivalent: ALARM_INFO_LEVEL,
      labelColor: '#f0d50a'
    },
    {
      min: 5,
      max: 7.5,
      color: '#ED862F',
      label: 'comfort.bad',
      dashboardColor: '#ff8605',
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#ff8605'
    },
    {
      min: 7.5,
      max: 10,
      color: '#FF0000',
      label: 'comfort.critical',
      dashboardColor: '#fd1717',
      alertEquivalent: ALARM_CRITICAL_LEVEL,
      labelColor: '#fd1717'
    }
  ],
  [VIRAL]: [
    {
      min: 0,
      max: 2.5,
      color: '#77be69',
      label: 'viral.low',
      dashboardColor: '#48c494', // GREEN
      alertEquivalent: ALARM_DEFAULT_LEVEL,
      labelColor: '#48c494'
    },
    {
      min: 2.5,
      max: 5,
      color: '#FFDC00',
      label: 'viral.moderate',
      dashboardColor: '#f0d50a', // YELLOW
      alertEquivalent: ALARM_INFO_LEVEL,
      labelColor: '#f0d50a'
    },
    {
      min: 5,
      max: 7.5,
      color: '#ED862F',
      label: 'viral.high',
      dashboardColor: '#ff8605', // ORANGE
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#ff8605'
    },
    {
      min: 7.5,
      max: 10,
      color: '#FF0000',
      label: 'viral.critical',
      dashboardColor: '#fd1717', // RED
      alertEquivalent: ALARM_CRITICAL_LEVEL,
      labelColor: '#fd1717'
    }
  ],
  [SMOKE]: [
    {
      min: 0,
      max: 1,
      color: '#77be69',
      label: 'smoke_index.no',
      dashboardColor: '#7FD6B4', // GREEN
      alertEquivalent: ALARM_DEFAULT_LEVEL,
      labelColor: '#48C494'
    },
    {
      min: 1,
      max: 2,
      color: '#FF0000',
      label: 'smoke_index.yes',
      dashboardColor: '#D7524B', // RED
      alertEquivalent: ALARM_CRITICAL_LEVEL,
      labelColor: '#E62E2E'
    }
  ],
  [CONFINEMENT]: [
    {
      min: 0,
      max: 2.5,
      label: 'confinement.good',
      color: '#77be69',
      dashboardColor: '#48c494', // GREEN
      alertEquivalent: ALARM_DEFAULT_LEVEL,
      labelColor: '#48C494'
    },
    {
      min: 2.5,
      max: 3.5,
      label: 'confinement.moderate',
      color: '#FFDC00',
      dashboardColor: '#f0d50a', // YELLOW
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#f0d50a'
    },
    {
      min: 3.5,
      max: 4.5,
      label: 'confinement.high',
      color: '#ED862F',
      dashboardColor: '#ff8605',
      alertEquivalent: ALARM_WARNING_LEVEL,
      labelColor: '#ff8605'
    },
    {
      min: 4.5,
      max: 6,
      label: 'confinement.critical',
      color: '#7a2680',
      dashboardColor: '#fd1717',
      alertEquivalent: ALARM_CRITICAL_LEVEL,
      labelColor: '#fd1717'
    }
  ]
};

export const CO2 = 'co2';
export const TVOC = 'voc';
export const PM1 = 'pm1';
export const PM2_5 = 'pm2_5';
export const PM4 = 'pm4';
export const PM10 = 'pm10';
export const TEMP = 'temperature';
export const HUM = 'humidity';
export const PRESS = 'barometer';
export const NOISE = 'soundLevel';
export const LIGHT_SENSOR = 'light';
export const INDEX = 'index';
export const SENSORS = 'sensors';

export const DASHBOARD_PARAMS_ORDER = {
  [INDEX]: {
    [AQI_DASHBOARD]: 1,
    [COMFORT]: 2,
    [CONFINEMENT]: 3,
    [VIRAL]: 4
  },
  [SENSORS]: {
    [CO2]: 1,
    [TVOC]: 2,
    [PM1]: 3,
    [PM2_5]: 4,
    [PM4]: 5,
    [PM10]: 6,
    [TEMP]: 7,
    [HUM]: 8,
    [PRESS]: 9,
    [NOISE]: 10,
    [LIGHT_SENSOR]: 11
  }
};

export const ONE_TIME_MODE = 'ONE_TIME_MODE';
export const AUTOMATIC_MODE = 'AUTOMATIC_MODE';
export const SCHEDULED_MODE = 'SCHEDULED_MODE';
export const EXTERNAL_MODE = 'EXTERNAL_MODE';

export const REARMING_MODE_WT1 = [ONE_TIME_MODE];
export const REARMING_MODE_WT1_MK3 = [ONE_TIME_MODE, AUTOMATIC_MODE, SCHEDULED_MODE, EXTERNAL_MODE];
export const REARMING_MODE_WT1_MK3_NO_ALARM = [SCHEDULED_MODE, EXTERNAL_MODE];

export const USER_ROLES = {
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_EXPERT: 'ROLE_EXPERT',
  ROLE_VIEWER: 'ROLE_VIEWER'
};

export const DASHBOARD_TYPES = {
  INDOOR: 'INDOOR'
};

export const METRIC_PREFIX = {
  G: 1000000000,
  M: 1000000,
  k: 1000
};

export const CSV_METHOD = 'CSV';
export const TIMEFRAME_METHOD = 'TIMEFRAME';
export const PREVIEW_MODE = 'PREVIEW';
