import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import Root from '@appShared/Root';
import initYup from '@config/yup-config';

initYup();

/*
  TODO: move dsn to .env and then

  if (process.env.SENTRY_DSN) {
    Sentry.init()
  }

*/
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d1a04e4c69b341239de94dae8654eeea@o557381.ingest.sentry.io/5689540',
    integrations: [browserTracingIntegration()],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications'
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

const Desktop = () => <Root />;

export default Desktop;
