import { get } from 'lodash';
import { setLocale } from 'react-redux-i18n';
import userServices from '@services/user';
import { UPDATE_USER } from '@redux/actions/users';

export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER_LOCAL = 'UPDATE_USER_LOCAL';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER = 'GET_USER';
export const FETCH_USERS = 'FETCH_USERS';

export function createUser(user) {
  return {
    type: CREATE_USER,
    payload: userServices.create(user)
  };
}

export function getUser(userId) {
  return {
    type: GET_USER,
    payload: userServices.get(userId)
  };
}

export function updateCurrentUser(user) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER_LOCAL,
      payload: { user }
    });

    dispatch(setLocale(get(user, 'preferences.lang')));

    await dispatch({
      type: UPDATE_USER,
      payload: userServices.update(user._id, user)
    });
    // const { users: updatedUsers } = getState();
    // const updatedUser = updatedUsers.find(u => u._id === updatedUser._id);
  };
}

export function deleteUser(user) {
  return {
    type: DELETE_USER,
    payload: userServices.delete(user._id)
  };
}

export function fetchUsers() {
  return {
    type: FETCH_USERS,
    payload: userServices.fetch()
  };
}
