import deviceServices from '@services/device';

export const CREATE_DEVICE = 'CREATE_DEVICE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const UPDATE_DEVICE_CONFIGURATION_LOCK = 'UPDATE_DEVICE_CONFIGURATION_LOCK';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const GET_DEVICE = 'GET_DEVICE';
export const CREATE_ONOFF_DEVICE = 'CREATE_ONOFF_DEVICE';
export const DELETE_ONOFF_DEVICE = 'DELETE_ONOFF_DEVICE';
export const UPDATE_ONOFF_DEVICE = 'UPDATE_ONOFF_DEVICE';
export const CREATE_VIRTUALSENSORS_DEVICE = 'CREATE_VIRTUALSENSORS_DEVICE';
export const DELETE_VIRTUALSENSORS_DEVICE = 'DELETE_VIRTUALSENSORS_DEVICE';
export const UPDATE_VIRTUALSENSORS_DEVICE = 'UPDATE_VIRTUALSENSORS_DEVICE';
export const CREATE_SENSOR_DEVICE = 'CREATE_SENSOR_DEVICE';
export const DELETE_SENSOR_DEVICE = 'DELETE_SENSOR_DEVICE';
export const UPDATE_SENSOR_DEVICE = 'UPDATE_SENSOR_DEVICE';
export const HIDE_SENSOR_DEVICE = 'HIDE_SENSOR_DEVICE';
export const HIDE_VIRTUALSENSOR_DEVICE = 'HIDE_VIRTUALSENSOR_DEVICE';

export function createDevice(device) {
  if (device.tech_specs.kind !== 'POD') {
    delete device.frame_type;
  }

  return {
    type: CREATE_DEVICE,
    payload: deviceServices.create(device)
  };
}

// TODO: REFACTO THIS ! Ugly ! No mutation needed !
export function updateDevice(device) {
  // Remove id if needed
  const copiedDevice = { ...device };
  const deviceId = copiedDevice._id;
  delete copiedDevice._id;

  let deviceFrameType = copiedDevice.frame_type;

  if (copiedDevice.tech_specs.kind !== 'POD') {
    delete copiedDevice.frame_type;
  } else if (copiedDevice.tech_specs.kind === 'POD' && !deviceFrameType) {
    deviceFrameType = 0;
  }

  // Filter data to post
  const filteredDevice = {
    site_id: copiedDevice.site_id,
    name: copiedDevice.name,
    geo_coords: copiedDevice.geo_coords,
    subscriptionDate: copiedDevice.subscriptionDate,
    tech_specs: copiedDevice.tech_specs,
    model_id: copiedDevice.model_id === '' ? null : copiedDevice.model_id,
    sensors_list: copiedDevice.sensors_list,
    combine_list: copiedDevice.combine_list,
    identification: copiedDevice.identification,
    suppression: copiedDevice.suppression,
    permanent: copiedDevice.permanent > 0 ? copiedDevice.permanent : null,
    questionnaire_id: copiedDevice.questionnaire_id,
    four_twenty: copiedDevice.four_twenty,
    on_off: copiedDevice.on_off,
    frame_type: deviceFrameType
  };

  return {
    type: UPDATE_DEVICE,
    payload: deviceServices.update(deviceId, filteredDevice)
  };
}

export const updateDevices = (modelId, deviceList) => ({
  type: UPDATE_DEVICES,
  payload: deviceServices.updateDevices(modelId, deviceList)
});

export function updateDeviceConfigurationLock(deviceId, locked) {
  return {
    type: UPDATE_DEVICE_CONFIGURATION_LOCK,
    payload: deviceServices.updateConfigurationLock(deviceId, locked)
  };
}

export function deleteDevice(deviceId) {
  return {
    type: DELETE_DEVICE,
    payload: deviceServices.delete(deviceId)
  };
}

export function createVirtualSensors(deviceId, virtualSensor) {
  return async (dispatch) => {
    const result = await deviceServices.createVirtualSensors(deviceId, virtualSensor);
    dispatch({
      type: `${CREATE_VIRTUALSENSORS_DEVICE}_FULFILLED`,
      payload: { new_combine_list: result, device_id: deviceId }
    });
  };
}
export function deleteVirtualSensors(deviceId, virtualSensorName) {
  return async (dispatch) => {
    const result = await deviceServices.deleteVirtualSensors(deviceId, virtualSensorName);
    dispatch({
      type: `${DELETE_VIRTUALSENSORS_DEVICE}_FULFILLED`,
      payload: { new_combine_list: result, device_id: deviceId }
    });
  };
}
export function updateVirtualSensors(deviceId, virtualSensor) {
  return async (dispatch) => {
    const result = await deviceServices.updateVirtualSensors(deviceId, virtualSensor);
    dispatch({
      type: `${UPDATE_VIRTUALSENSORS_DEVICE}_FULFILLED`,
      payload: { new_combine_list: result, device_id: deviceId }
    });
  };
}

export function hideVirtualSensor(deviceId, virtualSensor) {
  return async (dispatch) => {
    const result = await deviceServices.hideVirtualSensor(deviceId, virtualSensor);
    dispatch({
      type: HIDE_VIRTUALSENSOR_DEVICE,
      payload: { new_combine_list: result, device_id: deviceId }
    });
  };
}

export function createSensors(deviceId, sensor) {
  return {
    type: CREATE_SENSOR_DEVICE,
    payload: deviceServices.createSensors(deviceId, sensor)
  };
}

export function deleteSensors(deviceId, sensorName) {
  return {
    type: DELETE_SENSOR_DEVICE,
    payload: deviceServices.deleteSensors(deviceId, sensorName)
  };
}

export function updateSensors(deviceId, sensor) {
  return async (dispatch) => {
    const result = await deviceServices.updateSensors(deviceId, sensor);
    dispatch({
      type: UPDATE_SENSOR_DEVICE,
      payload: { sensors: result, device_id: deviceId }
    });
  };
}

export function hideSensor(deviceId, sensor) {
  return async (dispatch) => {
    const result = await deviceServices.hideSensor(deviceId, sensor);
    dispatch({
      type: HIDE_SENSOR_DEVICE,
      payload: { sensors: result, device_id: deviceId }
    });
  };
}

export function fetchDevices() {
  return {
    type: FETCH_DEVICES,
    payload: deviceServices.fetchDevices()
  };
}

export function getDevice(deviceId) {
  return {
    type: GET_DEVICE,
    payload: deviceServices.getDevice(deviceId)
  };
}

export function createOnOff(deviceId, onOff) {
  return async (dispatch) => {
    const result = await deviceServices.createOnOff(deviceId, onOff);
    dispatch({
      type: `${CREATE_ONOFF_DEVICE}_FULFILLED`,
      payload: { onOff: result.config, device_id: deviceId }
    });
  };
}
export function deleteOnOff(deviceId) {
  return async (dispatch) => {
    await deviceServices.deleteOnOff(deviceId);
    dispatch({
      type: `${DELETE_ONOFF_DEVICE}_FULFILLED`,
      payload: { onOff: null, device_id: deviceId }
    });
  };
}
export function updateOnOff(deviceId, onOff) {
  return async (dispatch) => {
    const result = await deviceServices.updateOnOff(deviceId, onOff);
    dispatch({
      type: `${UPDATE_ONOFF_DEVICE}_FULFILLED`,
      payload: { onOff: result.config, device_id: deviceId }
    });
  };
}
