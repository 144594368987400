import axios from './axios';

const timePeriodServices = {
  create: (timePeriod) => {
    const options = {
      method: 'POST',
      url: 'timeperiods',
      data: timePeriod
    };

    return axios(options);
  },

  update: (timePeriodId, timePeriod) => {
    const options = {
      method: 'PATCH',
      url: `timeperiods/${timePeriodId}`,
      data: timePeriod
    };

    return axios(options);
  },

  delete: (timePeriodId) => {
    const options = {
      method: 'PATCH',
      url: `timeperiods/${timePeriodId}/delete`
    };

    return axios(options);
  },

  fetchTimePeriods: () => {
    const options = {
      method: 'GET',
      url: 'timeperiods'
    };

    return axios(options);
  }
};

export default timePeriodServices;
