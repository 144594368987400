import { REDUX_FORM_TYPES } from '@tools/constants';
import { withFormReducer, withFormInitialState } from './withFormReducer';
import { FETCH_USERS, CREATE_USER, UPDATE_USER, DELETE_USER } from '../actions/users';

const { USERS } = REDUX_FORM_TYPES;

const initialState = {
  ...withFormInitialState,
  list: []
};

const usersReducer = (state = initialState, action) => {
  const { list } = state;
  switch (action.type) {
    case `${FETCH_USERS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.users
      };
    case `${CREATE_USER}_FULFILLED`:
      return {
        ...state,
        list: [...list, action.payload.user]
      };
    case `${UPDATE_USER}_FULFILLED`: {
      const updatedUser = action.payload.user;
      const updatedUsers = state.list.map((user) => {
        if (user._id === updatedUser._id) {
          return updatedUser;
        }
        return user;
      });
      return {
        ...state,
        list: updatedUsers
      };
    }

    case `${DELETE_USER}_FULFILLED`: {
      const deletedUser = action.payload.user;
      const deletedUserIndex = list.findIndex((u) => u._id === deletedUser._id);
      if (deletedUserIndex !== -1) {
        return {
          ...state,
          list: [...list.slice(0, deletedUserIndex), ...list.slice(deletedUserIndex + 1)]
        };
      }
      return { ...state };
    }
    default:
      return state;
  }
};

export default withFormReducer(USERS, usersReducer);
