import { REDUX_FORM_TYPES } from '@tools/constants';
import { withFormReducer, withFormInitialState } from './withFormReducer';
import {
  CREATE_CALCULUS,
  UPDATE_CALCULUS,
  FETCH_CALCULUS,
  FETCH_CALCULUS_BY_ID,
  DELETE_CALCULUS,
  CHANGE_MIGRATE_CALCULUS
} from '../actions/calculus';

const upsertCalculus = (calculuses, newCalculus) => {
  const newCalculusIndex = calculuses.findIndex((c) => c._id === newCalculus._id);
  return calculuses
    .map((c, i) => (i === newCalculusIndex ? newCalculus : c))
    .concat(newCalculusIndex > -1 ? [] : newCalculus);
};

const { CALCULUS } = REDUX_FORM_TYPES;

const initialState = {
  ...withFormInitialState,
  list: []
};

const calculusReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_CALCULUS}_FULFILLED`:
      return state;

    case `${UPDATE_CALCULUS}_FULFILLED`:
      return {
        ...state,
        list: upsertCalculus(state.list, action.payload.calculus)
      };

    case `${FETCH_CALCULUS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.calculus
      };

    case `${FETCH_CALCULUS_BY_ID}_FULFILLED`:
      return {
        ...state,
        list: upsertCalculus(state.list, action.payload.calculus)
      };

    case `${DELETE_CALCULUS}_FULFILLED`:
      return {
        ...state,
        list: state.list.filter((calculus) => calculus._id !== action.payload.calculus._id)
      };

    case `${CHANGE_MIGRATE_CALCULUS}_FULFILLED`:
      return {
        ...state,
        list: upsertCalculus(state.list, action.payload.calculus)
      };

    default:
      return state;
  }
};

export default withFormReducer(CALCULUS, calculusReducer);
