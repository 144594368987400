import { REDUX_FORM_TYPES } from '@tools/constants';
import { withFormReducer, withFormInitialState } from './withFormReducer';
import { CREATE_TIME_PERIOD, UPDATE_TIME_PERIOD, DELETE_TIME_PERIOD, FETCH_TIME_PERIODS } from '../actions/timePeriod';

const initialState = {
  ...withFormInitialState,
  list: []
};

const { TIME_PERIODS } = REDUX_FORM_TYPES;

const timePeriodReducer = (state = initialState, action) => {
  switch (action.type) {
    // Time Period
    case `${CREATE_TIME_PERIOD}_FULFILLED`:
      return {
        ...state,
        list: [...state.list, action.payload.timeperiod]
      };

    case `${UPDATE_TIME_PERIOD}_FULFILLED`:
      return {
        ...state,
        list: state.list.map((timePeriod) =>
          timePeriod._id === action.payload.timeperiod._id ? action.payload.timeperiod : timePeriod
        )
      };

    case `${DELETE_TIME_PERIOD}_FULFILLED`:
      return {
        ...state,
        list: state.list.filter((timePeriod) => timePeriod._id !== action.payload.timeperiod._id)
      };

    case `${FETCH_TIME_PERIODS}_FULFILLED`:
      return {
        ...state,
        list: [...action.payload.timeperiods]
      };

    default:
      return state;
  }
};

export default withFormReducer(TIME_PERIODS, timePeriodReducer);
