import axios from './axios';

const modelServices = {
  clone: (model) => {
    const options = {
      method: 'POST',
      url: `models/duplicate/${model.parent_id}`,
      data: JSON.stringify({
        name: model.name,
        site_id: model.site_id
      })
    };

    return axios(options);
  },

  create: (model) => {
    const options = {
      method: 'POST',
      url: 'models',
      data: model
    };

    return axios(options);
  },

  update: (modelId, model) => {
    const options = {
      method: 'PATCH',
      url: `models/${modelId}`,
      data: model
    };

    return axios(options);
  },

  fetchModels: () => {
    const options = {
      method: 'GET',
      url: 'models'
    };

    return axios(options);
  },

  delete: (modelId) => {
    const options = {
      method: 'PATCH',
      url: `models/${modelId}/delete`
    };

    return axios(options);
  }
};

export default modelServices;
