import { REDUX_FORM_TYPES } from '@tools/constants';
import { withFormReducer, withFormInitialState } from './withFormReducer';
import { CREATE_ALARM, UPDATE_ALARM, DELETE_ALARM, FETCH_ALARMS } from '../actions/alarm';

const { ALARMS } = REDUX_FORM_TYPES;

const initialState = {
  ...withFormInitialState,
  list: []
};

const alarmReducer = (state = initialState, action) => {
  switch (action.type) {
    // Alarms
    case `${CREATE_ALARM}_FULFILLED`:
      return {
        ...state,
        list: [...state.list, action.payload.alarm]
      };

    case `${UPDATE_ALARM}_FULFILLED`:
      return {
        ...state,
        list: state.list.map((alarm) => (alarm._id === action.payload.alarm._id ? action.payload.alarm : alarm))
      };

    case `${DELETE_ALARM}_FULFILLED`:
      return {
        ...state,
        list: state.list.filter((alarm) => alarm._id !== action.payload.alarm._id)
      };

    case `${FETCH_ALARMS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.alarms
      };

    default:
      return state;
  }
};

export default withFormReducer(ALARMS, alarmReducer);
