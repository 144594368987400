import languages from 'Lang';
import translationsObject, { DEFAULT_LANGUAGE } from 'rubix-translation';
import { PREF_STORAGE_KEY } from '@tools/constants';
import { getLocalStorage, prefixedKey } from '@tools/localStorage';

export const getLocale = () => {
  const { lang } = getLocalStorage(prefixedKey(PREF_STORAGE_KEY)) || {};

  let locale = lang || DEFAULT_LANGUAGE;

  if (locale && Object.keys(translationsObject).includes(locale)) {
    return locale;
  }

  const locales = navigator.languages;

  for (let i = 0; i < locales.length; i++) {
    if (Object.keys(translationsObject).includes(locales[i].substr(0, 2))) {
      locale = locales[i].substr(0, 2);
      return locale;
    }
  }

  return window.ELLONA_SETTINGS.defaultLanguage;
};

export const retrieveLang = (language) => {
  let lang = 'Not found';
  languages.forEach((l) => {
    if (l.locale === language) {
      ({ lang } = l);
    }
  });

  return lang;
};

export const retrieveFlag = (language) => {
  let flag = 'us';

  languages.forEach((l) => {
    if (l.locale === language) {
      ({ flag } = l);
    }
  });
  return flag;
};

export { translationsObject };
