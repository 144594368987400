import viralServices from '@services/viral';

export const FETCH_VIRALS = 'FETCH_VIRALS';

export function fetchVirals() {
  return {
    type: FETCH_VIRALS,
    payload: viralServices.fetch()
  };
}
