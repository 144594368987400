import { createStore, applyMiddleware, compose } from 'redux';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { translationsObject, getLocale } from '@tools/languageTools';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import promise from 'redux-promise-middleware';

import createRootReducer from './reducers';

const loggerMiddleware = createLogger({ collapsed: true, diff: true });

export default function configureStore(history, preloadedState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  let middleware = [promise, thunkMiddleware];

  // add redux logger is not production environment
  if (process.env.NODE_ENV === 'production5') {
    middleware = [...middleware, loggerMiddleware];
  }

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  // Configure i18n
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translationsObject));
  const locale = getLocale();
  store.dispatch(setLocale(locale));

  return store;
}
