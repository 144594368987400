import { alpha, createTheme } from '@mui/material';
import { THEMES } from '@tools/constants';

export const otherTheme = (theme) => THEMES.find((t) => t !== theme);

const mainColor = '#6ac4d6';
// const mainColorDarker = '#7EC2D4';
const TRUE_BLACK = '#000';
const TRUE_WHITE = '#FFF';
const BLACK = '#353a3b';
const WHITE = '#f2f2f2';
const GRAY = '#bdbdbd';
const DANGER = '#e57373';
const BG_WHITE = '#FAFBFC';
const BG_BLACK = '#1B2233';
const BG_WHITE_2ND = '#dedfe0';
const BG_WHITE_2ND_DIV = '#FFFFFF';
const BG_BLACK_2ND = '#363a4e';
const BG_BLACK_2ND_DIV = BG_BLACK_2ND; // '#223344';

// DASHBOARD
const DASHBOARD_TEXT1 = '#4D5E80';
const DASHBOARD_TEXT2 = '#6B7A99';
const DASHBOARD_TEXT3 = '#8896B4';
const DASHBOARD_TEXT1_INVERTED = '#F7F8FA';
const DASHBOARD_TEXT2_INVERTED = '#ADB8CC';
const DASHBOARD_TEXT3_INVERTED = '#99A6BF';
const DASHBOARD_BG_WHITE = TRUE_WHITE;
const DASHBOARD_BG_BLACK = '#273048';
const DASHBOARD_ICON_COLOR = '#8896B4';
const DASHBOARD_ICON_COLOR_INVERTED = '#7789AD';
const DASHBOARD_GREY_BACKGROUND = '#F5F6F7';

// TODO: remove those exported variables, pass through theme selector
export const darkBackgroundDefault = BLACK;
export const lightBackgroundDefault = WHITE;

export const getThemeVariables = (theme, isCssOutput) => {
  const isDarkTheme = theme === THEMES[0];
  const textColor = isDarkTheme ? WHITE : BLACK;
  const backgroundColor = isDarkTheme ? BG_BLACK : BG_WHITE; // isDarkTheme ? BLACK : WHITE;
  const hoverColor = isDarkTheme ? '#434860' : '#ebebeb'; // alpha(textColor, isDarkTheme ? 0.1 : 0.1);
  const selectedColor = alpha(isDarkTheme ? BG_BLACK_2ND : BG_WHITE_2ND, isDarkTheme ? 1 : 0.5);
  const disabledColor = alpha(textColor, 0.3);
  const borderColor = alpha(textColor, 0.23);
  const boxShadowColor = isDarkTheme ? '#0d0d0d' : '#ccc';

  if (isCssOutput) {
    return {
      /* 'color-scheme': isDarkTheme ? 'dark' : 'light', */
      '--invert': isDarkTheme ? 'invert(1)' : '',
      '--text-color': textColor,
      '--bg-color': backgroundColor,
      '--bg-color-transparency': backgroundColor + '99',
      '--hover-color': hoverColor,
      '--selected-color': selectedColor,
      '--disabled-color': disabledColor,
      '--border-color': borderColor,
      '--main-color': mainColor,
      '--bg-color-secondary': isDarkTheme ? BG_BLACK_2ND : BG_WHITE_2ND,
      '--pure-bg-color': isDarkTheme ? TRUE_BLACK : TRUE_WHITE,
      '--bg-color-secondary-div': isDarkTheme ? BG_BLACK_2ND_DIV : BG_WHITE_2ND_DIV,
      '--box-shadow-color': boxShadowColor,
      '--drag-h': `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='30'><path d='M3 0 v30 M7 0 v30' fill='none' stroke='${textColor.replace(
        '#',
        '%23'
      )}' /></svg>")`,
      '--drag-v': `url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' width='30' height='10'><path d='M0 3 h30 M0 7 h30' fill='none' stroke='${textColor.replace(
        '#',
        '%23'
      )}' /></svg>")`
    };
  }

  return {
    palette: {
      mode: isDarkTheme ? 'dark' : 'light',
      isDarkTheme,
      type: theme,
      text: {
        primary: textColor,
        inverted: backgroundColor,
        secondary: GRAY,
        disabled: disabledColor,
        hint: hoverColor,
        icon: hoverColor
      },
      primary: {
        main: mainColor,
        light: '#87cfde',
        dark: '#4a8995',
        contrastText: BG_WHITE,
        danger: DANGER
      },
      secondary: isDarkTheme
        ? {
            main: DASHBOARD_BG_BLACK, // '#292f34',
            light: '#30373d',
            dark: '#1d2023',
            color: '#bdbdbd',
            contrastText: BG_WHITE
          }
        : {
            main: '#d8d9d9',
            light: '#d8d9d9',
            dark: '#d8d9d9',
            color: '#1d2023',
            contrastText: '#1d2023'
          },
      white: BG_WHITE,
      trueWhite: TRUE_WHITE,
      dashboard: {
        text: {
          dashboardText1: DASHBOARD_TEXT1,
          dashboardText2: DASHBOARD_TEXT2,
          dashboardText3: DASHBOARD_TEXT3,
          dashboardText1Inverted: DASHBOARD_TEXT1_INVERTED,
          dashboardText2Inverted: DASHBOARD_TEXT2_INVERTED,
          dashboardText3Inverted: DASHBOARD_TEXT3_INVERTED
        },
        dashboardWhite: DASHBOARD_BG_WHITE,
        dashboardBlack: DASHBOARD_BG_BLACK,
        dashboardIconColor: isDarkTheme ? DASHBOARD_ICON_COLOR_INVERTED : DASHBOARD_ICON_COLOR,
        dashboardGreyBackground: DASHBOARD_GREY_BACKGROUND
      },
      error: {
        main: '#903838',
        light: '#ec3030'
      },
      alert: {
        info: '#ffdc00',
        warn: '#ed862f',
        crit: '#f00'
      },
      success: {
        main: '#1E9600'
      },
      boxShadowColor,
      fieldBorder: borderColor,
      divider: alpha(textColor, 0.12),
      background: {
        default: backgroundColor,
        paper: isDarkTheme ? DASHBOARD_BG_BLACK : TRUE_WHITE,
        main: isDarkTheme ? BG_BLACK : BG_WHITE,
        secondary: isDarkTheme ? BG_BLACK_2ND : BG_WHITE_2ND,
        tooltip: alpha(backgroundColor, 0.9)
      },
      action: {
        active: isDarkTheme ? TRUE_WHITE : TRUE_BLACK,
        hover: hoverColor, // 'rgba(255, 255, 255, 0.08)',
        hoverOpacity: 0.08,
        selected: alpha(textColor, 0.5), // 'rgba(255, 255, 255, 0.16)',
        selectedOpacity: 0.16,
        disabled: disabledColor, // 'rgba(255, 255, 255, 0.3)',
        disabledBackground: ''
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: ['Open Sans', 'sans-serif'].join(',')
    },
    components: {
      MuiAppBar: {
        colorDefault: {
          backgroundColor: isDarkTheme ? TRUE_BLACK : BG_WHITE,
          color: isDarkTheme ? TRUE_WHITE : TRUE_BLACK
        }
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary'
        },
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            minWidth: '100px'
          },
          contained: {
            '& svg': {
              fill: textColor
            },
            '&.Mui-disabled': {
              '& svg': {
                fill: disabledColor
              }
            },
            '&:hover': {
              backgroundColor: hoverColor
            }
          },
          iconSizeMedium: {
            '& > svg:first-of-type': {
              width: '1rem',
              height: '1rem'
            },
            '& > img:first-of-type': {
              width: '1rem',
              height: '1rem'
            }
          }
        }
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: isDarkTheme ? TRUE_WHITE : TRUE_BLACK,
            '&.Mui-selected': {
              backgroundColor: mainColor
            }
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '0px 5px'
          },
          colorSecondary: {
            color: '#ccc',
            '&.Mui-checked': '#ccc'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            color: textColor,
            backgroundColor: isDarkTheme ? '#616161' : '#e0e0e0'
          }
        }
      },
      MuiRadio: {
        styleOverrides: {
          colorSecondary: {
            '&.Mui-checked': '#ccc'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkTheme ? DASHBOARD_BG_BLACK : TRUE_WHITE,
            color: isDarkTheme ? TRUE_WHITE : TRUE_BLACK
          },
          elevation0: {
            border: `thin solid ${borderColor}`
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: isDarkTheme ? DASHBOARD_BG_BLACK : TRUE_WHITE
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            position: 'relative',
            color: isDarkTheme ? '#dadada' : '#585858'
          }
        }
      },
      MuiBadge: {
        badge: {
          borderShadow: `inset 0px 0px 1px ${textColor}`
        }
      },
      MuiSlider: {
        styleOverrides: {
          track: {
            '&.Mui-vertical': {
              width: '10px'
            }
          },
          thumb: {
            width: '30px',
            height: '30px'
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          colorSecondary: {
            '&.Mui-checked': {
              color: mainColor
            },
            '&.Mui-checked + .MuiSwitch-track': {
              backgroundColor: mainColor
            }
          },
          track: {
            backgroundColor: '#ccc'
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '0',
            marginRight: '0.5rem',
            '& svg': {
              width: '1.5rem',
              height: '1.5rem'
            }
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '& .MuiTypography-root': {
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }
        }
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            '&.is-compact': {
              // background: 'red'
            }
          },
          responsiveBase: {
            overflow: 'auto',
            maxHeight: 'none'
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            '.is-compact &': {
              padding: '0.25rem'
            },
            '& > *': {
              display: 'flex'
            }
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem'
          }
        }
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            minWidth: '300px',
            backgroundColor: isDarkTheme ? DASHBOARD_BG_BLACK : DASHBOARD_BG_WHITE
          }
        }
      }
    }
  };
};

export const getRubixTheme = (theme) => createTheme(getThemeVariables(theme));

export const makeInlineStyles = (theme) => (applyFn) => applyFn(getThemeVariables(theme));
