import { REDUX_FORM_TYPES } from '@tools/constants';
import { findById } from '@tools/arrayTools';
import { withFormReducer, withFormInitialState } from './withFormReducer';
import {
  CREATE_DEVICE,
  UPDATE_DEVICE,
  UPDATE_DEVICES,
  UPDATE_DEVICE_CONFIGURATION_LOCK,
  GET_DEVICE,
  DELETE_DEVICE,
  FETCH_DEVICES,
  CREATE_ONOFF_DEVICE,
  DELETE_ONOFF_DEVICE,
  UPDATE_ONOFF_DEVICE,
  CREATE_VIRTUALSENSORS_DEVICE,
  DELETE_VIRTUALSENSORS_DEVICE,
  UPDATE_VIRTUALSENSORS_DEVICE,
  UPDATE_SENSOR_DEVICE,
  HIDE_SENSOR_DEVICE,
  HIDE_VIRTUALSENSOR_DEVICE
} from '../actions/device';

const { DEVICE } = REDUX_FORM_TYPES;

const initialState = {
  ...withFormInitialState,
  list: []
};

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_DEVICE}_FULFILLED`:
      return {
        ...state,
        list: [...state.list, action.payload.device]
      };

    case `${UPDATE_DEVICE}_FULFILLED`:
    case `${GET_DEVICE}_FULFILLED`:
    case `${UPDATE_DEVICE_CONFIGURATION_LOCK}_FULFILLED`:
      return {
        ...state,
        list: state.list.map((device) => (device._id === action.payload.device._id ? action.payload.device : device))
      };

    case `${CREATE_VIRTUALSENSORS_DEVICE}_FULFILLED`:
    case `${DELETE_VIRTUALSENSORS_DEVICE}_FULFILLED`:
    case `${UPDATE_VIRTUALSENSORS_DEVICE}_FULFILLED`:
    case HIDE_VIRTUALSENSOR_DEVICE:
      return {
        ...state,
        list: state.list.map((device) =>
          device._id === action.payload.device_id
            ? {
                ...device,
                combine_list: action.payload.new_combine_list.vsensors_list
              }
            : device
        )
      };
    case UPDATE_SENSOR_DEVICE:
    case HIDE_SENSOR_DEVICE:
      return {
        ...state,
        list: state.list.map((device) =>
          device._id === action.payload.device_id
            ? {
                ...device,
                sensors_list: action.payload.sensors.sensors_list
              }
            : device
        )
      };
    case `${CREATE_ONOFF_DEVICE}_FULFILLED`:
    case `${DELETE_ONOFF_DEVICE}_FULFILLED`:
    case `${UPDATE_ONOFF_DEVICE}_FULFILLED`:
      return {
        ...state,
        list: state.list.map((device) =>
          device._id === action.payload.device_id
            ? {
                ...device,
                on_off: action.payload.onOff
              }
            : device
        ),
        // Probably to delete after refactor IHM device configuration
        editing: {
          ...state.list.filter((device) => device._id === action.payload.device_id)[0],
          on_off: action.payload.onOff
        }
      };

    case `${UPDATE_DEVICES}_FULFILLED`: {
      const updatedDevices = action.payload.devices;

      const updatedList = state.list.map((device) => {
        const updatedDevice = findById(updatedDevices, device._id);

        return {
          ...device,
          model_id: updatedDevice ? updatedDevice.model_id : device.model_id
        };
      });

      return {
        ...state,
        list: updatedList
      };
    }

    case `${DELETE_DEVICE}_FULFILLED`:
      return {
        ...state,
        list: state.list.filter((device) => device._id !== action.payload.device._id)
      };

    case `${FETCH_DEVICES}_FULFILLED`:
      return {
        ...state,
        list: action.payload.devices
      };

    default:
      return state;
  }
};

export default withFormReducer(DEVICE, deviceReducer);
