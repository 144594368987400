import comfortServices from '@services/comfort';

export const FETCH_COMFORTS = 'FETCH_COMFORTS';

export function fetchComforts() {
  return {
    type: FETCH_COMFORTS,
    payload: comfortServices.fetch()
  };
}
