import isSmokeServices from '@services/isSmoke';

export const FETCH_IS_SMOKE = 'FETCH_IS_SMOKE';

export function fetchSmokes() {
  return {
    type: FETCH_IS_SMOKE,
    payload: isSmokeServices.fetch()
  };
}
