import checkFormulaSensorService from '@services/checkFormulaSensor';
import sensorServices from '@services/sensor';

export const SELECT_SENSOR = 'SELECT_SENSOR';
export const SELECT_SENSOR_LIST = 'SELECT_SENSOR_LIST';
export const UNSELECT_SENSOR = 'UNSELECT_SENSOR';
export const SELECT_ALL_SENSOR = 'SELECT_ALL_SENSOR';
export const UNSELECT_ALL_SENSOR = 'UNSELECT_ALL_SENSOR';
export const VALIDATION_COMPENSATION_SENSOR_FORMULA = 'VALIDATION_COMPENSATION_SENSOR_FORMULA';
export const VALIDATION_CUSTOM_SENSOR_FORMULA = 'VALIDATION_CUSTOM_SENSOR_FORMULA';
export const FETCH_SENSORS = 'FETCH_SENSORS';

// TODO: clean those actions
export function selectSensor(sensor) {
  return {
    type: SELECT_SENSOR,
    sensor
  };
}

export function selectSensorList(sensorList) {
  return {
    type: SELECT_SENSOR_LIST,
    sensorList
  };
}

export function unselectSensor(sensor) {
  return {
    type: UNSELECT_SENSOR,
    sensor
  };
}

export function selectAllSensor() {
  return {
    type: SELECT_ALL_SENSOR
  };
}

export function unselectAllSensor() {
  return {
    type: UNSELECT_ALL_SENSOR
  };
}

export function validateCompensationSensorFormula(formula) {
  return {
    type: VALIDATION_COMPENSATION_SENSOR_FORMULA,
    payload: checkFormulaSensorService.validateFormula(formula)
  };
}

export function validateCustomSensorFormula(formula, deviceId) {
  return {
    type: VALIDATION_CUSTOM_SENSOR_FORMULA,
    payload: checkFormulaSensorService.validateCustomFormula(formula, deviceId)
  };
}

export function fetchSensors() {
  return {
    type: FETCH_SENSORS,
    payload: sensorServices.fetch()
  };
}
