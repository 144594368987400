import reportService from '@services/reports';

export const FETCH_REPORTS = 'FETCH_REPORTS';
export const SHARE_EMAIL_ADDRESS_REPORT = 'SHARE_EMAIL_ADDRESS_REPORT';

export function fetchReports() {
  return {
    type: FETCH_REPORTS,
    payload: reportService.fetchReports()
  };
}

export function shareEmailAddressReport(reportId, addEmail) {
  return {
    type: SHARE_EMAIL_ADDRESS_REPORT,
    payload: reportService.shareEmailAddressReport(reportId, addEmail)
  };
}
