import axios from './axios';

const isSmokeServices = {
  fetch: () => {
    const options = {
      method: 'GET',
      url: 'smokes'
    };

    return axios(options);
  }
};

export default isSmokeServices;
