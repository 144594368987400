import axios from './axios';

const deviceServices = {
  create: (device) => {
    const options = {
      method: 'POST',
      url: 'devices',
      data: device
    };

    return axios(options);
  },

  update: (deviceId, device) => {
    const options = {
      method: 'PATCH',
      url: `devices/${deviceId}`,
      data: device
    };

    return axios(options);
  },

  updateDevices: (modelId, deviceList) => {
    const options = {
      method: 'POST',
      url: 'updatedevices',
      data: {
        model_id: modelId,
        device_id: deviceList
      }
    };

    return axios(options);
  },

  updateConfigurationLock: (deviceId, locked) => {
    const options = {
      method: 'PATCH',
      url: `devices/${deviceId}`,
      data: { locked }
    };

    return axios(options);
  },

  delete: (deviceId) => {
    const options = {
      method: 'DELETE',
      url: `devices/${deviceId}/delete`
    };

    return axios(options);
  },

  createVirtualSensors: async (deviceId, virtualSensor) => {
    const options = {
      method: 'POST',
      url: `devices/${deviceId}/virtualsensors`,
      data: virtualSensor
    };
    return axios(options);
  },

  deleteVirtualSensors: async (deviceId, virtualSensorName) => {
    const options = {
      method: 'DELETE',
      url: `devices/${deviceId}/virtualsensors/${virtualSensorName}`
    };
    return axios(options);
  },

  updateVirtualSensors: async (deviceId, virtualSensor) => {
    const options = {
      method: 'PATCH',
      url: `devices/${deviceId}/virtualsensors/${virtualSensor.virtualsensor.name}`,
      data: virtualSensor
    };
    return axios(options);
  },

  hideVirtualSensor: (deviceId, virtualSensor) => {
    const options = {
      method: 'PATCH',
      url: `devices/${deviceId}/hidevsensor/${virtualSensor.name}`,
      data: { visible: virtualSensor.visible }
    };
    return axios(options);
  },

  createSensors: (deviceId, sensor) => {
    const options = {
      method: 'POST',
      url: `devices/${deviceId}/sensors`,
      data: sensor
    };
    return axios(options);
  },

  deleteSensors: (deviceId, sensorName) => {
    const options = {
      method: 'DELETE',
      url: `devices/${deviceId}/sensors/${sensorName}`
    };
    return axios(options);
  },

  updateSensors: (deviceId, sensor) => {
    const options = {
      method: 'PATCH',
      url: `devices/${deviceId}/sensors/${sensor.name}`,
      data: { sensor }
    };
    return axios(options);
  },

  hideSensor: (deviceId, sensor) => {
    const options = {
      method: 'PATCH',
      url: `devices/${deviceId}/hidesensor/${sensor.name}`,
      data: { visible: sensor.visible }
    };
    return axios(options);
  },

  fetchDevices: () => {
    const options = {
      method: 'GET',
      url: 'devices'
    };

    return axios(options);
  },

  getDevice: (deviceId) => {
    const options = {
      method: 'GET',
      url: `devices/${deviceId}`
    };

    return axios(options);
  },

  getOnOff: (deviceId) => {
    const options = {
      method: 'GET',
      url: `onoff/${deviceId}`
    };

    return axios(options);
  },

  createOnOff: (deviceId, onOff) => {
    const options = {
      method: 'POST',
      url: `onoff/${deviceId}`,
      data: onOff
    };

    return axios(options);
  },

  deleteOnOff: (deviceId) => {
    const options = {
      method: 'DELETE',
      url: `onoff/${deviceId}`
    };

    return axios(options);
  },

  updateOnOff: (deviceId, onOff) => {
    const options = {
      method: 'PATCH',
      url: `onoff/${deviceId}`,
      data: onOff
    };

    return axios(options);
  },

  toggleOnOff: (deviceId, value) => {
    const options = {
      method: 'PATCH',
      url: `onoff/${deviceId}`,
      data: { triggered: value }
    };

    return axios(options);
  }
};

export default deviceServices;
