import axios from './axios';

const reportService = {
  fetchReports: () => {
    const options = {
      method: 'GET',
      url: 'reports-data'
    };

    return axios(options);
  },
  shareEmailAddressReport: (reportId, addEmail) => {
    const options = {
      method: 'POST',
      url: `reports-data/share/${reportId}`,
      data: {
        send_to: addEmail
      }
    };

    return axios(options);
  }
};

export default reportService;
