import { API_ACTIONS } from '@tools/constants';
import { FORM_EDIT, FORM_RESET, FORM_ERROR, DELETION_CONFIRM } from '../actions/form';

export const withFormInitialState = {
  editing: null,
  removing: null,
  error: null
};

const singularize = (str) => str.replace(/s$/i, '');

const isFullFilledOrResetAction = (prefix, type) => {
  const isResetAction = type === `${prefix}_${FORM_RESET}`;
  const isFulfilledAction = API_ACTIONS.reduce(
    (acc, action) =>
      acc || type === `${action}_${prefix}_FULFILLED` || type === `${action}_${singularize(prefix)}_FULFILLED`,
    false
  );
  return isResetAction || isFulfilledAction;
};

export const withFormReducer = (prefix, reducer) => {
  if (typeof prefix !== 'string') {
    console.error('PREFIX MISSING');
  }
  return (state, action) => {
    const reducedState = reducer(state, action);
    switch (action.type) {
      case `${prefix}_${FORM_EDIT}`:
        return {
          ...reducedState,
          editing: action.payload.data
        };
      case `${prefix}_${FORM_ERROR}`:
        return {
          ...reducedState,
          error: action.payload.data
        };
      case `${prefix}_${DELETION_CONFIRM}`:
        return {
          ...reducedState,
          removing: action.payload.id
        };
      default:
        if (isFullFilledOrResetAction(prefix, action.type)) {
          return {
            ...reducedState,
            ...withFormInitialState
          };
        }
        return reducedState;
    }
  };
};
