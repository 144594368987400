import { get } from 'lodash';

export const getSitePath = (sites, initialNode) => {
  if (!initialNode) {
    return [];
  }
  const parentSiteId = get(initialNode, 'site_id') || get(initialNode, 'parent_site');
  const parentSite = sites.find((s) => s._id === parentSiteId);
  return [...(parentSite ? getSitePath(sites, parentSite) : []), initialNode];
};

export const getNodePathString = (sites, node) =>
  getSitePath(sites, node).reduce((str, { name }) => `${str}/ ${name} `, '');

export const getClosestModelId = (nodesById, nodeId, models) => {
  do {
    const node = nodesById[nodeId];

    if (!node) {
      break;
    } // Can happen with test data, TODO SHould be removed

    const model = models.find((m) => m.site_id === node._id);
    if (model) {
      return model._id;
    }

    nodeId = node.parent_site; // eslint-disable-line no-param-reassign
  } while (nodeId !== undefined);

  return null;
};

export const loopFromNodeToTop = (nodesById, nodeId, callbackFunction) => {
  const node = nodesById[nodeId];

  if (!node) {
    return;
  }
  callbackFunction(node);
  const newNode = node.parent_site || node.site_id;
  if (newNode) {
    loopFromNodeToTop(nodesById, newNode, callbackFunction);
  }

  // do {
  //   const node = nodesById[nodeId];

  //   if (!node) {
  //     break;
  //   } // Can happen with test data, TODO Should be removed

  //   eachFunction(node);

  //   nodeId = node.parent_site; // eslint-disable-line no-param-reassign
  // } while (nodeId !== undefined);

  // return null;
};

//
// Alerts in tree
//

export const enhanceWithAlerts = (nodesById, alertsByDeviceId) => {
  const alertByNodeId = {};

  // Loop over each deviceId and its alerts
  Object.keys(alertsByDeviceId).forEach((deviceId) => {
    const alerts = alertsByDeviceId[deviceId];

    // If alerts, add length to all device's parent
    if (alerts) {
      const alertCount = alerts.length;
      const alertLevel = alerts.reduce((level, alert) => Math.max(level, alert.level), 0);

      loopFromNodeToTop(nodesById, deviceId, (node) => {
        const alertCtx = alertByNodeId[node._id];
        alertByNodeId[node._id] = {
          alertLevel: alertCtx ? Math.max(alertCtx.alertLevel, alertLevel) : alertLevel,
          alertCount: (alertCtx ? alertCtx.alertCount : 0) + alertCount
        };
      });
    }
  });

  return alertByNodeId;
};

//
// Tool functions for building coords box of a given site
//

export const DEFAULT_CENTER = {
  lat: 0,
  long: 0
};

const defaultBox = {
  ne: {
    lat: 90,
    lng: -180
  },
  sw: {
    lat: -90,
    lng: 180
  }
};

export const INITIAL_BOUNDS_BOX = {
  ne: {
    lat: 90,
    lng: 180
  },
  sw: {
    lat: -90,
    lng: -180
  }
};

const buildCoordBox = (node, bbox) => {
  if (node.geo_coords && node.geo_coords.lat !== 0 && node.geo_coords.long !== 0) {
    return {
      ne: {
        lat: Math.min(bbox.ne.lat, node.geo_coords.lat),
        lng: Math.max(bbox.ne.lng, node.geo_coords.long)
      },
      sw: {
        lat: Math.max(bbox.sw.lat, node.geo_coords.lat),
        lng: Math.min(bbox.sw.lng, node.geo_coords.long)
      }
    };
  }

  return bbox;
};

export const convertArrayToLatLng = (array) => {
  const bbox = {
    sw: {
      lng: array[0],
      lat: array[1]
    },
    ne: {
      lng: array[2],
      lat: array[3]
    }
  };

  return bbox;
};

export const convertLatLngToArray = (bbox) => [bbox.sw.lng, bbox.sw.lat, bbox.ne.lng, bbox.ne.lat];

export const getBoundsBox = (site) => {
  const initialBox = { ...defaultBox };

  let boundsBox = buildCoordBox(site, initialBox);

  // No point found
  if (boundsBox === initialBox) {
    return null;
  }

  // We have only point or two points too close
  if (boundsBox.ne.lat === boundsBox.sw.lat && boundsBox.ne.lng === boundsBox.sw.lng) {
    boundsBox = {
      ne: {
        lat: boundsBox.ne.lat - 0.05,
        lng: boundsBox.ne.lng + 0.05
      },
      sw: {
        lat: boundsBox.sw.lat + 0.05,
        lng: boundsBox.sw.lng - 0.05
      }
    };
  } else if (boundsBox.ne.lat === boundsBox.sw.lat) {
    boundsBox.ne.lat -= 0.05;
    boundsBox.ne.lat += 0.05;
  } else if (boundsBox.ne.lng === boundsBox.sw.lng) {
    boundsBox.ne.lng += 0.05;
    boundsBox.ne.lng -= 0.05;
  }

  return boundsBox;
};

export const getBoundsBoxForElements = (elements) => {
  let bbox = { ...defaultBox };

  elements.forEach((element) => {
    if (element.geo_coords && element.geo_coords.lat !== 0 && element.geo_coords.long !== 0) {
      bbox = {
        ne: {
          lat: Math.min(bbox.ne.lat, element.geo_coords.lat),
          lng: Math.max(bbox.ne.lng, element.geo_coords.long)
        },
        sw: {
          lat: Math.max(bbox.sw.lat, element.geo_coords.lat),
          lng: Math.min(bbox.sw.lng, element.geo_coords.long)
        }
      };
    }
  });

  // If we have only one point
  /*
    This happened when several sites or devices
    has been defined at the same geocoords. This need to be
    refacto but may need some backend work
  */
  if (bbox.ne.lat === bbox.sw.lat && bbox.ne.lng === bbox.sw.lng) {
    bbox = {
      ne: {
        lat: bbox.ne.lat - 0.0001,
        lng: bbox.ne.lng + 0.0001
      },
      sw: {
        lat: bbox.sw.lat + 0.0001,
        lng: bbox.sw.lng - 0.0001
      }
    };
  }

  return bbox;
};

export const buildSitesWithPlumes = (sites, plumes) => {
  const buildSites = sites.map((site) => {
    plumes.forEach((plume) => {
      // old way no need to parse
      if (plume.site_id === site._id) {
        site.plumes = {
          _id: plume._id,
          site: plume.site_id,
          geo_coords: plume.geo_coords,
          coords: plume.geo_coords.split(',').map((coord) => parseFloat(coord)),
          zoom: plume.geo_coords.zoom,
          legend: plume.legend,
          old: true
        };
      }
      // new way
      if (plume.site === site._id) {
        site.plumes = {
          _id: plume._id,
          site: plume.site,
          coords: [
            plume.coordinates.topLeftCorner.latitude,
            plume.coordinates.topLeftCorner.longitude,
            plume.coordinates.bottomRightCorner.latitude,
            plume.coordinates.bottomRightCorner.longitude
          ],
          geo_coords: {
            lat: (plume.coordinates.topLeftCorner.latitude + plume.coordinates.bottomRightCorner.latitude) / 2,
            long: (plume.coordinates.topLeftCorner.longitude + plume.coordinates.bottomRightCorner.longitude) / 2
          },
          zoom: plume.coordinates.zoom,
          legend: plume.legend
        };
        if (!site.geo_coords.lat && !site.geo_coords.long) {
          site.geo_coords = {
            lat: (plume.coordinates.topLeftCorner.latitude + plume.coordinates.bottomRightCorner.latitude) / 2,
            long: (plume.coordinates.topLeftCorner.longitude + plume.coordinates.bottomRightCorner.longitude) / 2
          };
        }
      }
    });
    return site;
  });

  return buildSites;
};

export const getFirstCommonParent = (site) => {
  if (site.children && site.children.length === 1) {
    return getFirstCommonParent(site.children);
  }
  return site;
};

export const canDisplayOnMap = (eltList) =>
  eltList.some((el) => {
    const long = get(el, 'geo_coords.long');
    const lat = get(el, 'geo_coords.lat');
    return long && long !== 0 && lat && lat !== 0;
  });

export const withDefaultGeoCoords = (element) => {
  if (!get(element, 'geo_coords.lat') && !get(element, 'geo_coords.long')) {
    return {
      ...element,
      geo_coords: {
        lat: DEFAULT_CENTER.lat,
        long: DEFAULT_CENTER.long
      }
    };
  }
  return element;
};
