import { buildSitesWithPlumes } from '@tools/siteTools';
import { REDUX_FORM_TYPES } from '@tools/constants';
import { withFormReducer, withFormInitialState } from './withFormReducer';
import {
  CREATE_SITE,
  UPDATE_SITE,
  GET_SITE,
  DELETE_SITE,
  FETCH_SITES,
  DELETE_SITE_MAP,
  SAVE_SITE_MAP
} from '../actions/site';
import { FETCH_PLUMES } from '../actions/plumes';

const { SITE } = REDUX_FORM_TYPES;

const initialState = {
  ...withFormInitialState,
  siteList: [],
  deviceList: [],
  plumesList: []
};

const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_SITE}_FULFILLED`:
      return {
        ...state,
        siteList: [...state.siteList, action.payload.site]
      };

    case `${SAVE_SITE_MAP}_FULFILLED`:
    case `${DELETE_SITE_MAP}_FULFILLED`:
    case `${UPDATE_SITE}_FULFILLED`:
    case `${GET_SITE}_FULFILLED`:
      return {
        ...state,
        siteList: state.siteList.map((site) =>
          site._id === action.payload.site._id
            ? { ...action.payload.site } // { ...site, ...action.payload.site }
            : site
        )
      };

    case `${DELETE_SITE}_FULFILLED`:
      return {
        ...state,
        siteList: state.siteList.filter((site) => site._id !== action.payload.site._id)
      };

    case `${FETCH_SITES}_FULFILLED`:
      return {
        ...state,
        siteList: buildSitesWithPlumes(action.payload.sites, state.plumesList)
      };

    case `${FETCH_PLUMES}_FULFILLED`:
      return {
        ...state,
        plumesList: action.payload.plumes
      };

    default:
      return state;
  }
};

export default withFormReducer(SITE, siteReducer);
