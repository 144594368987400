import axios from './axios';

const alarmServices = {
  create: (alarm) => {
    const options = {
      method: 'POST',
      url: 'alarms',
      data: alarm
    };

    return axios(options);
  },

  update: (alarmId, alarm) => {
    const options = {
      method: 'PATCH',
      url: `alarms/${alarmId}`,
      data: alarm
    };

    return axios(options);
  },

  delete: (alarmId) => {
    const options = {
      method: 'PATCH',
      url: `alarms/${alarmId}/delete`
    };

    return axios(options);
  },

  fetchAlarms: () => {
    const options = {
      method: 'GET',
      url: 'alarms'
    };

    return axios(options);
  }
};

export default alarmServices;
