export const FORM_EDIT = 'FORM_EDIT';
export const FORM_RESET = 'FORM_RESET';
export const FORM_SUBMIT = 'FORM_SUBMIT';
export const FORM_ERROR = 'FORM_ERROR';
export const DELETION_CONFIRM = 'DELETION_CONFIRM';

const actionWithType = (actionName, type) => `${type.toUpperCase()}_${actionName}`;

export const formEdit = (type, data) => ({
  type: actionWithType(FORM_EDIT, type),
  payload: { data }
});

export const formReset = (type) => ({
  type: actionWithType(FORM_RESET, type)
});

export const formSubmit = (type, data) => ({
  type: actionWithType(FORM_SUBMIT, type),
  payload: { data }
});

export const formError = (type, data) => ({
  type: actionWithType(FORM_ERROR, type),
  payload: { data }
});

export const deleteConfirm = (type, id) => ({
  type: actionWithType(DELETION_CONFIRM, type),
  payload: { id }
});
