import Axios from 'axios';
import { getLocalStorage, prefixedKey } from '@tools/localStorage';
import { TOKEN_STORAGE_KEY } from '@tools/constants';

const controllers = {};

export default async (
  options,
  { returnFullResult = false, baseURL = window.ELLONA_SETTINGS.apiUrl, cancelName = null } = {}
) => {
  if (baseURL === window.ELLONA_SETTINGS.apiUrl) {
    options.headers = {
      ...options.headers,
      'x-auth': getLocalStorage(prefixedKey(TOKEN_STORAGE_KEY)),
      'Content-Type': 'application/json'
    };
  }

  let controller;
  if (cancelName) {
    if (controllers[cancelName]) {
      controllers[cancelName].abort();
      // console.warn('Request cancelling:', cancelName);
    }
    controller = new AbortController();
    controllers[cancelName] = controller;
    options.signal = controller.signal;
  }

  try {
    const res = await Axios({
      ...options,
      headers: {
        ...options.headers
      },
      url: baseURL + options.url,
      signal: cancelName ? controller.signal : null
    });
    return returnFullResult ? res : res.data;
  } catch (err) {
    if (Axios.isCancel(err)) {
      console.warn('Request canceled:', err);
      // if (cancelName) {
      // delete controllers[cancelName];
      // }
      return -1;
    }
    console.error('Services error :', err);
    throw err;
  }
};
