import axios from './axios';

const authServices = {
  login: (user, password) => {
    const options = {
      method: 'POST',
      url: 'login',
      data: {
        login: user,
        password
      }
    };

    return axios(options);
  },

  forgotPassword: (email) => {
    const options = {
      method: 'POST',
      url: 'forgottenpassword/generatemail',
      data: {
        mail: email
      }
    };

    return axios(options);
  },

  resetPassword: (password, token, id) => {
    const options = {
      method: 'POST',
      url: `forgottenpassword/resetpassword?token=${token}&id=${id}`,
      data: {
        password
      }
    };

    return axios(options);
  }
};

export default authServices;
