import { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import OverlayLoading from '@components/ui/loading/OverlayLoading';
import configureStore from '@redux/configureStore';
import ThemeWrapper from '@appShared/wrappers/theme/ThemeWrapper';
import getMedia from '@mobile/utils/getMedia';
import '@styles/general.scss';

const Router = lazy(() => import('@desktopPages/Router'));
const MobileRouter = lazy(() => import('@mobile/menu/MobileRouter'));
const store = configureStore();

const media = getMedia();

const Root = () => (
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <ThemeWrapper>
        <SnackbarProvider>
          <Suspense fallback={<OverlayLoading />}>{media !== 'desktop' ? <MobileRouter /> : <Router />}</Suspense>
        </SnackbarProvider>
      </ThemeWrapper>
    </Provider>
  </DndProvider>
);

export default Root;
