import surveyServices from '@services/survey';

export const CLONE_SURVEY = 'CLONE_SURVEY';
export const CREATE_SURVEY = 'CREATE_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const DELETE_SURVEY = 'DELETE_SURVEY';
export const GET_SURVEY = 'GET_SURVEY';
export const FETCH_SURVEYS = 'FETCH_SURVEYS';

export function cloneSurvey(survey) {
  return {
    type: CLONE_SURVEY,
    payload: surveyServices.clone(survey)
  };
}

export function createSurvey(survey) {
  return {
    type: CREATE_SURVEY,
    payload: surveyServices.create(survey)
  };
}

export function updateSurvey(survey) {
  return {
    type: UPDATE_SURVEY,
    payload: surveyServices.update(survey._id, survey)
  };
}

export const deleteLocally = (surveyId) => ({
  type: DELETE_SURVEY,
  payload: surveyId
});

export const deleteSurvey = (surveyId) => (dispatch) => {
  dispatch(deleteLocally(surveyId));
  dispatch({
    type: DELETE_SURVEY,
    payload: surveyServices.delete(surveyId)
  });
};

export function fetchSurveys() {
  return {
    type: FETCH_SURVEYS,
    payload: surveyServices.getSurveys()
  };
}
