import { getLocalStorage, prefixedKey } from '@tools/localStorage';
import { PREF_STORAGE_KEY, THEMES } from '@tools/constants';
import USER_RIGHTS from 'UserRights';
import { DEFAULT_LANGUAGE } from 'rubix-translation';
import withSyncStorage from './withSyncStorage';
import { GET_USER, UPDATE_USER_LOCAL } from '../actions/user';

const initialPreferences = getLocalStorage(prefixedKey(PREF_STORAGE_KEY)) || {
  theme: THEMES[0],
  lang: DEFAULT_LANGUAGE
};

const initialState = {
  role: null,
  rights: Object.fromEntries(USER_RIGHTS.map((r) => [r, false])),
  preferences: initialPreferences
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_LOCAL:
    case `${GET_USER}_FULFILLED`:
      return {
        ...state,
        ...action.payload.user
      };

    default:
      return state;
  }
};

export default withSyncStorage(userReducer, 'preferences', PREF_STORAGE_KEY);
