import { REDUX_FORM_TYPES } from '@tools/constants';
import { withFormReducer, withFormInitialState } from './withFormReducer';
import { CLONE_SURVEY, FETCH_SURVEYS, DELETE_SURVEY, CREATE_SURVEY, UPDATE_SURVEY } from '../actions/survey';

const { SURVEYS } = REDUX_FORM_TYPES;

const initialState = {
  ...withFormInitialState,
  list: []
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_SURVEY:
      return {
        ...state,
        list: state.list.filter((s) => s._id !== action.payload)
      };
    case `${CLONE_SURVEY}_FULFILLED`:
      return {
        ...state,
        list: [...state.list, action.payload.new_questionnaire]
      };
    case `${CREATE_SURVEY}_FULFILLED`:
      return {
        ...state,
        list: [...state.list, action.payload.questionnaire]
      };
    case `${UPDATE_SURVEY}_FULFILLED`: {
      const { questionnaire: updatedSurvey } = action.payload;
      return {
        ...state,
        list: state.list.map((q) => {
          if (q._id === updatedSurvey._id) {
            return updatedSurvey;
          }
          return q;
        })
      };
    }
    case `${FETCH_SURVEYS}_FULFILLED`:
      return {
        list: action.payload.questionnaires
      };

    default:
      return state;
  }
};

export default withFormReducer(SURVEYS, surveyReducer);
