import { FETCH_ALERTS, FETCH_ACTIVE_ALERTS } from '../actions/alert';

const initialState = {
  list: [],
  activeList: [],
  activeByDeviceId: {},

  activeFilteredList: [],
  activeFilteredByDeviceId: {}
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ACTIVE_ALERTS}_FULFILLED`:
      // ToDo: Check deep equality between state.activeList & action.payload.data
      return {
        ...state,
        activeList: action.payload.data
      };

    case `${FETCH_ALERTS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.data
      };

    default:
      return state;
  }
};

export default alertReducer;
