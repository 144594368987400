import { isEqual } from 'lodash';

export const getLocalStorage = (key = '') => {
  try {
    const serializedItem = localStorage.getItem(key);
    if (serializedItem === null) {
      return undefined;
    }
    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

export const updateLocalStorage = (stateToPersist) => {
  try {
    Object.keys(stateToPersist).forEach((key) => {
      const serializedState = JSON.stringify(stateToPersist[key]);
      const persistedState = getLocalStorage(key);
      if (!persistedState || !isEqual(persistedState, stateToPersist[key])) {
        localStorage.setItem(key, serializedState);
      }
    });
  } catch (err) {
    // Ignore errors
  }
};

export const prefixedKey = (key) => `RUBIX-${key.toUpperCase()}`;
