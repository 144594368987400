import aqiDashboardServices from '@services/aqi_dashboard';

export const FETCH_AQI_DASHBOARDS = 'FETCH_AQI_DASHBOARDS';

export function fetchAqis() {
  return {
    type: FETCH_AQI_DASHBOARDS,
    payload: aqiDashboardServices.fetch()
  };
}
