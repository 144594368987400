import axios from './axios';

const extractionServices = {
  create: (extraction) => {
    let options;

    if (extraction.ts === 'audit') {
      delete extraction.timezone;
      delete extraction.devices;
      delete extraction.sampling;
      delete extraction.sampling_mode;

      options = {
        method: 'POST',
        url: 'extractions',
        data: extraction
      };
    } else {
      options = {
        method: 'POST',
        url: 'extractions',
        data: extraction
      };
    }

    return axios(options);
  },

  delete: (extractionId) => {
    const options = {
      method: 'DELETE',
      url: `extractions/${extractionId}`
    };

    return axios(options);
  },

  fetchExtractions: () => {
    const options = {
      method: 'GET',
      url: 'extractions'
    };

    return axios(options);
  },

  fetchPending: () => {
    const options = {
      method: 'GET',
      url: 'extractions?status=pending'
    };

    return axios(options);
  },

  downloadExtractions: (extractionId) => {
    const options = {
      responseType: 'arraybuffer',
      method: 'GET',
      url: `extractions/${extractionId}`
    };

    return axios(options);
  }
};

export default extractionServices;
