import { updateLocalStorage, prefixedKey } from '@tools/localStorage';
import { get } from 'lodash';

export default (reducer, path, key) => (state, action) => {
  const reducedState = reducer(state, action);
  const stateToPersist = get(reducedState, path);

  updateLocalStorage({
    [prefixedKey(key)]: stateToPersist
  });

  return reducedState;
};

// import {updateLocalStorage, getLocalStorage, prefixedKey} from '@tools/localStorage';
// import { updateObjectDeepKey } from '@tools/formTools';
// import { get } from 'lodash';

// export default (name, subStatePath = '') => {
//   const storageState = getLocalStorage(prefixedKey(name))
//   return {
//     buildInitialState: defaultInitialState =>
//        updateObjectDeepKey(defaultInitialState, subStatePath.split('.'), storageState),
//     withSyncStorage: (reducer) => (state, action) => {
//       const reducedState = reducer(state, action);
//       updateLocalStorage({
//         [prefixedKey(key)]: get(reducedState, path)
//       });

//       return reducedState;
//     }
//   }
// }
