import configs from 'SensorConfigs';
import { FETCH_COMFORTS } from '@redux/actions/comfort';
import { FETCH_VIRALS } from '@redux/actions/viral';
import { FETCH_AQI_DASHBOARDS } from '@redux/actions/aqiDashboard';
import { FETCH_IS_SMOKE } from '@redux/actions/isSmoke';
import { FETCH_CONFINEMENTS } from '@redux/actions/confinement';
import { FETCH_SENSORS } from '@redux/actions/sensorConfigs';
import { parseRealSensorToIdiotPreviousConfig } from '@tools/sensor';
// const types = [...new Set(sensors.map(s => s.type))];

const initialState = {
  list: configs,
  selectedSensorTypes: [],
  comforts: [],
  virals: [],
  aqiDashboards: [],
  smokes: [],
  confinements: []
};

const sensorConfigs = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_SENSORS}_FULFILLED`:
      return {
        ...state,
        list: parseRealSensorToIdiotPreviousConfig(state.list, action.payload)
      };

    case `${FETCH_COMFORTS}_FULFILLED`:
      return {
        ...state,
        comforts: action.payload.comforts
      };

    case `${FETCH_VIRALS}_FULFILLED`:
      return {
        ...state,
        virals: action.payload.virals
      };

    case `${FETCH_AQI_DASHBOARDS}_FULFILLED`:
      return {
        ...state,
        aqiDashboards: action.payload.aqis
      };

    case `${FETCH_IS_SMOKE}_FULFILLED`:
      return {
        ...state,
        smokes: action.payload.smokes
      };

    case `${FETCH_CONFINEMENTS}_FULFILLED`:
      return {
        ...state,
        confinements: action.payload.confinements
      };

    default:
      return state;
  }
};

export default sensorConfigs;
