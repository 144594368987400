import { setLocale } from 'yup';

export default () =>
  setLocale({
    mixed: {
      required: ' ', // 'global.form.requiredField',
      default: 'formValidation.invalid'
    },
    string: {
      required: ' ', // 'global.form.requiredField',
      email: 'formValidation.email',
      url: 'formValidation.url'
    },
    number: {
      required: ' ',
      positive: 'formValidation.positive',
      integer: 'formValidation.integer',
      min: ({ min }) => ({ key: 'formValidation.relativeMin', values: min }),
      max: ({ max }) => ({ key: 'formValidation.relativeMax', values: max })
    }
  });
