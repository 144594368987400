import userServices from '@services/user';

export const FETCH_USERS = 'FETCH_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export function fetchUsers() {
  return {
    type: FETCH_USERS,
    payload: userServices.fetch()
  };
}

export function createUser(user) {
  return {
    type: CREATE_USER,
    payload: userServices.create(user)
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    payload: userServices.update(user._id, user)
  };
}

export function deleteUser(userId) {
  return {
    type: DELETE_USER,
    payload: userServices.delete(userId)
  };
}
