import { CircularProgress } from '@mui/material';
import { keyframes } from '@emotion/react';

const fillOpacity = (opacity) => keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: ${opacity}
  }
`;

const OverlayLoading = ({ opacity }) => (
  <div
    style={{
      position: 'absolute',
      zIndex: 1000,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: opacity ? 'rgba(0,0,0,0)' : 'inherit',
      opacity: opacity ? '0' : '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      animation: opacity ? `${fillOpacity} 0.5s ease 0.15s normal forwards` : 'none'
    }}
  >
    <CircularProgress sx={{ height: '40px', width: '40px', zIndex: 1001 }} />
  </div>
);

export default OverlayLoading;
