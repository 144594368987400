import React from 'react';
import ReactDOM from 'react-dom/client';
import Desktop from './app-desktop/index';

ReactDOM.createRoot(document.getElementById('app')).render(<Desktop />);

/*
<React.StrictMode>
  <Desktop />
</React.StrictMode>,
*/
