import axios from './axios';

const calculusServices = {
  create: (calculus) => {
    const options = {
      method: 'POST',
      url: 'calculus',
      data: calculus
    };

    return axios(options);
  },

  update: (calculus) => {
    const options = {
      method: 'PATCH',
      url: `calculus/${calculus._id}`,
      data: calculus
    };

    return axios(options);
  },

  fetchCalculus: (type) => {
    let url = 'calculus';

    if (type) {
      url = `calculus/?type=${type}`;
    }

    const options = {
      method: 'GET',
      url
    };

    return axios(options);
  },

  fetchCalculusById: (id) => {
    const options = {
      method: 'GET',
      url: `calculus/${id}`
    };

    return axios(options);
  },

  deleteCalculus: (id) => {
    const options = {
      method: 'PATCH',
      url: `calculus/${id}/delete`
    };

    return axios(options);
  },

  fetchComputedCalculus: (id) => {
    const options = {
      method: 'GET',
      url: `calculus/${id}/details`
    };

    return axios(options);
  },

  validateCalculusCSV: (calculus) => {
    const options = {
      method: 'POST',
      url: 'calculus/validate_csv',
      data: calculus
    };

    return axios(options);
  },

  downloadCalculus: (calculusId) => {
    const options = {
      method: 'GET',
      url: `calculus/export/${calculusId}`
    };

    return axios(options, { returnFullResult: true });
  },

  changeMigrate: (id, bool) => {
    const options = {
      method: 'POST',
      url: `calculus/migrate/${id}`,
      data: { to_migrate: bool }
    };

    return axios(options);
  }
};

export default calculusServices;
