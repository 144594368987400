import confinementServices from '@services/confinement';

export const FETCH_CONFINEMENTS = 'FETCH_CONFINEMENTS';

export function fetchConfinements() {
  return {
    type: FETCH_CONFINEMENTS,
    payload: confinementServices.fetch()
  };
}
