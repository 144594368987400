import alertServices from '@services/alert';

export const FETCH_ALERTS = 'FETCH_ALERTS';
export const FETCH_ACTIVE_ALERTS = 'FETCH_ACTIVE_ALERTS';

export function fetchActiveAlerts(params) {
  return {
    type: FETCH_ACTIVE_ALERTS,
    payload: alertServices.fetchActiveAlerts(params)
  };
}

export function fetchAlerts(params) {
  return {
    type: FETCH_ALERTS,
    payload: alertServices.fetchAlerts(params)
  };
}
