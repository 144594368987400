export const first = (array) => array[0];
export const last = (array) => array[array.length - 1];

const basicEqualityFn = (a, b) => a === b;
export const idEquality = ({ _id }, { _id: _id2 }) => _id === _id2;
export const idEqualityCurried = (id) => (obj) => {
  // Check if 'id' is undefined or if 'obj' is falsy
  if (id === undefined || !obj || obj._id === undefined) {
    return false;
  }

  // Check equality of 'id' and 'obj._id'
  return id === obj._id;
};

export const findById = (array, id) => array.find(idEqualityCurried(id));
export const findIdxById = (array, id) => array.findIndex(idEqualityCurried(id));
export const someById = (array, id) => array.some(idEqualityCurried(id));

export const toggleInArray = (list, el, equalityFn = basicEqualityFn) => {
  const found = list.find((e) => equalityFn(e, el));
  return found ? list.filter((e) => e !== found) : [el, ...list];
};

export const toggleInArrayById = (list, el) => toggleInArray(list, el, idEquality);

export const insertUnique = (list, el, equalityFn = basicEqualityFn) => [el, ...list.filter((e) => !equalityFn(e, el))];

export const insertUniqueById = (list, el) => insertUnique(list, el, idEquality);

export const rejectById = (list, el) => list.filter((e) => !idEquality(e, el));

export const updateBy = (list, updatedElt, equalityFn = basicEqualityFn) =>
  list.map((el) => (equalityFn(el, updatedElt) ? updatedElt : el));

export const updateById = (list, updatedElt) => updateBy(list, updatedElt, idEquality);

export const partition = (array, testFn) =>
  array?.reduce
    ? array.reduce(
        ([trueElements, falseElements], element) => {
          const testResult = testFn(element);
          return [trueElements.concat(testResult ? element : []), falseElements.concat(testResult ? [] : element)];
        },
        [[], []]
      )
    : [[], []];

export const groupBy = (array, getKeyFn) =>
  array?.reduce((acc, element) => {
    const key = getKeyFn(element);
    return {
      ...acc,
      [key]: (acc[key] || []).concat(element)
    };
  }, {});

export const concatMap = (arrayOfArray, mapFn) => arrayOfArray.map(mapFn).reduce((acc, array) => acc.concat(array), []);

export const findBy = (array, mapFn, comparisonFn) =>
  array.reduce(
    (acc, element) => {
      const { value: foundValue } = acc;
      const value = mapFn(element);
      return comparisonFn(value, foundValue) ? { element, value } : acc;
    },
    { element: null, value: null }
  ).element;

export const findValue = (array, mapFn) => array.reduce((acc, element) => acc || mapFn(element), null);

export const findWithValue = (array, mapFn) =>
  array.reduce((acc, element) => {
    if (acc) {
      return acc;
    }
    const value = mapFn(element);
    if (value) {
      return [element, value];
    }
    return null;
  }, null);

export const maxBy = (array, mapFn) => findBy(array, mapFn, (a, b) => a >= b);
export const minBy = (array, mapFn) => findBy(array, mapFn, (a, b) => a <= b);

export const reverse = (array) => [...array].reverse();

export const getUniqueBy = (array, mapFn) => [...new Set(array.map(mapFn).filter((v) => v))];

export const sortByName = (array) => array.sort((a, b) => a.name.localeCompare(b.name));
