import plumesService from '@services/plumes';

export const FETCH_PLUMES = 'FETCH_PLUMES';

export function fetchPlumes() {
  return {
    type: FETCH_PLUMES,
    payload: plumesService.get()
  };
}
