import axios from './axios';

const surveyService = {
  clone: (survey) => {
    const options = {
      method: 'POST',
      url: `questionnaires/duplicate/${survey.parent_id}`,
      data: JSON.stringify({
        name: survey.name,
        site_id: survey.site_id
      })
    };

    return axios(options);
  },

  create: (survey) => {
    const options = {
      method: 'POST',
      url: 'questionnaires',
      data: survey
    };

    return axios(options);
  },

  update: (surveyId, survey) => {
    const options = {
      method: 'PATCH',
      url: `questionnaires/${surveyId}`,
      data: survey
    };

    return axios(options);
  },

  delete: (surveyId) => {
    const options = {
      method: 'DELETE',
      url: `questionnaires/${surveyId}`
    };

    return axios(options);
  },

  getSurveys: () => {
    const options = {
      method: 'GET',
      url: 'questionnaires'
    };

    return axios(options);
  },

  getSurvey: (surveyId) => {
    const options = {
      method: 'GET',
      url: `questionnaires/${surveyId}`
    };

    return axios(options);
  },

  getSurveysDevice: (deviceId) => {
    const options = {
      method: 'GET',
      url: `devices/${deviceId}/questionnaires`
    };

    return axios(options);
  },

  getQRCode: (deviceId) => {
    const options = {
      method: 'GET',
      url: `qrcodes/${deviceId}`,
      responseType: 'arraybuffer'
    };

    return axios(options, { returnFullResult: true });
  }
};

export default surveyService;
