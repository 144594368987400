import authServices from '@services/auth';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const INIT_COMPLETE = 'INIT_COMPLETE';

export function login(user, password) {
  return {
    type: LOGIN,
    payload: authServices.login(user, password)
  };
}

export function logout() {
  localStorage.removeItem('RUBIX-TOKEN');
  // localStorage.clear();
  // localStorage.removeItem('rubiX-token');
  // localStorage.removeItem('rubiX-id');

  return {
    type: LOGOUT
  };
}

export function forceLogout() {
  localStorage.removeItem('RUBIX-TOKEN');
  // localStorage.clear();
  // localStorage.removeItem('rubiX-token');
  // localStorage.removeItem('rubiX-id');

  return {
    type: FORCE_LOGOUT
  };
}

export function forgotPassword(email) {
  return {
    type: FORGOT_PASSWORD,
    payload: authServices.forgotPassword(email)
  };
}
