import { withFormReducer, withFormInitialState } from './withFormReducer';
import {
  CREATE_EXTRACTION,
  DELETE_EXTRACTION,
  FETCH_EXTRACTIONS,
  FETCH_PENDING_EXTRACTIONS
} from '../actions/extraction';

const initialState = {
  ...withFormInitialState,
  list: []
};

const extractionReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_EXTRACTION}_FULFILLED`:
      return {
        ...state,
        list: [action.payload.extraction, ...state.list]
      };

    case `${DELETE_EXTRACTION}_FULFILLED`:
      return {
        ...state,
        list: state.list.filter((extraction) => extraction._id !== action.payload.extraction._id)
      };

    case `${FETCH_EXTRACTIONS}_FULFILLED`:
      return {
        ...state,
        list: action.payload.extractions
      };

    case `${FETCH_PENDING_EXTRACTIONS}_FULFILLED`:
      return {
        ...state,

        // Remove extraction that were in pending mode and not anymore
        list: state.list.map((extraction) => {
          if (extraction.status === 'pending') {
            // If not present in new list, change status to _id
            const found = action.payload.extractions.find(
              (pendingExtraction) => pendingExtraction._id === extraction._id && pendingExtraction.status !== 'pending'
            );

            if (found) {
              extraction.status = found.status;
            }
          }

          return extraction;
        })
      };

    default:
      return state;
  }
};

export default withFormReducer('EXTRACTION', extractionReducer);
