import axios from './axios';

const checkFormulaSensorServices = {
  validateFormula: (formula) => {
    const options = {
      method: 'POST',
      url: 'checkformula',
      data: { formula }
    };

    return axios(options);
  },

  validateCustomFormula: (formula, deviceId) => {
    const options = {
      method: 'POST',
      url: `checkformula/vs/${deviceId}`,
      data: { formula }
    };

    return axios(options);
  }
};

export default checkFormulaSensorServices;
